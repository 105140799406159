<div *ngIf="wsLength!==0" class="container mt-4">
    <div class="row justify-content-center text-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-delay="200"
         data-aos-duration="1000" data-aos-easing="bounce">
        <div class="title col-md-9 mb-3"><span class="green">{{ wsLength }} tracks</span> to choose from!</div>
    </div>
    <div class="row justify-content-center text-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-delay="500"
         data-aos-duration="1000" data-aos-easing="bounce">
        <p class="col-sm-8 col-md-6 mb-3">
            Because we believe in Connecting the dots, we have prepared for you a lot of activities
            and events that revolve around this topic.
        </p>
    </div>
    <div class="row justify-content-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-delay="800"
         data-aos-duration="1000" data-aos-easing="bounce">
        <div class="row col-md-12 col-lg-10 col-xl-9 justify-content-center">
            <div *ngFor="let workshop of workshops">
                <div class="green bg-light-green m-2 p-2 px-4 rounded-pill">
                    <h5 class="m-0 p-0 fw-bold green">{{workshop}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>
