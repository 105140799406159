import { Component } from '@angular/core';

@Component({
  selector: 'app-participation-fee',
  templateUrl: './participation-fee.component.html',
  styleUrls: ['./participation-fee.component.scss']
})
export class ParticipationFeeComponent {

}
