<div class="container-fluid pt-4 pt-md-0">
    <img class="bg" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
         data-aos-easing="bounce" src="assets/images/yellow-background.svg">
    <img class="yellow-dots" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000"
         data-aos-easing="bounce" src="assets/images/yellow-dots.svg">
    <div class="container px-0">
        <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-6 col-xl-6" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
                 data-aos-easing="bounce">
                <img [defaultImage]="'assets/images/2-lazy.jpg'" [lazyLoad]="'assets/images/image2.jpg'"
                     class="image position-relative">
            </div>
            <div class="col-12 col-md-5 offset-md-1 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="400"
                 data-aos-duration="1000" data-aos-easing="bounce">
                <h2 class="mb-4">Learn in a <span>different way</span>!</h2>
                <p class="my-4">
                    This year we will learn how to turn this crisis into an <span class="green bold">opportunity</span>
                    to make the world a <span class="green bold">better place</span>!
                </p>
                <app-cta-arrow goto="/p/tracks-2023" label="See the Tracks"></app-cta-arrow>
            </div>
        </div>
    </div>
</div>
