<div class="container">
    <div class="row align-items-center">
        <div class="col-12 col-md-5" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000"
             data-aos-easing="bounce">
            <h2 class="fw-bold">
                <span>Have fun,</span> make friends, <span class="green">live in the moment!</span>
            </h2>
            <p class="my-4">Every year we get to meet people from all over the world, make friends, memories, and take
                part in the most fun activities in ISWinT.</p>
            <app-cta-arrow goto="/p/activities" label="Check out the Activities"></app-cta-arrow>
        </div>
        <div class="col-12 col-md-6 offset-md-1 mt-4 mt-md-0">
            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="bounce">
                <img [defaultImage]="'assets/images/1-lazy.jpg'" [lazyLoad]="'assets/images/image3.jpg'"
                     class="image-top"/>
            </div>
            <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" data-aos-easing="bounce">
                <img [defaultImage]="'assets/images/2-lazy.jpg'" [lazyLoad]="'assets/images/image1.jpg'"
                     class="image-bottom"/>
            </div>
        </div>
    </div>
</div>
