import { Component, OnInit, ViewChild } from '@angular/core';
import { TestimonialModel } from '../../../../models/testimonial-model';
import { TestimonialsSectionService } from '../../../../services/testimonials-section.service';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-testimonials-section',
    templateUrl: './testimonials-section.component.html',
    styleUrls: ['./testimonials-section.component.scss'],
    providers: [NgbCarouselConfig],
})
export class TestimonialsSectionComponent {

    @ViewChild('carousel', {static: true}) carousel: NgbCarousel;
    public testimonials: TestimonialModel[];

    constructor(private testimonialsService: TestimonialsSectionService,
                private config: NgbCarouselConfig) {
        this.testimonials = this.testimonialsService.getTestimonials();
        config.showNavigationIndicators = false;
        config.showNavigationArrows = false;
    }
}
