<div class="container mb-4">
    <ngb-carousel #carousel [showNavigationIndicators]="false" interval="2500">
        <ng-template *ngFor="let testimonial of testimonials; index as i" class="col-12 col-md-4" data-aos="fade-up"
                     data-aos-delay="400"
                     data-aos-duration="1000" data-aos-easing="bounce" ngbSlide>
            <div class="row" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="bounce">
                <img src="\assets\images\quote.svg" style="height: 10%; width: 10%;">
            </div>
            <div class="mt-lg-3" id="testimonial-content-{{i}}">
                <h3 class="fw-bold fst-italic text-center">
                    {{testimonial.message}}
                </h3>
                <p class="text-center">
                    {{testimonial.name}}, {{testimonial.country}}
                </p>
            </div>
        </ng-template>
    </ngb-carousel>
</div>

