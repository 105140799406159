<div class="d-flex flex-column">
    <app-apply-now-section></app-apply-now-section>
    <!--    <app-winners-section></app-winners-section>-->
    <!--    <app-events-section></app-events-section>-->
    <!--    <app-funds-your-initiative-section></app-funds-your-initiative-section>-->
    <!--    <app-theme-section></app-theme-section>-->
    <app-check-workshops-section></app-check-workshops-section>
    <app-check-activities-section></app-check-activities-section>
    <app-map></app-map>
    <app-workshops-list-section></app-workshops-list-section>
    <app-partners></app-partners>
    <app-testimonials-section></app-testimonials-section>
    <app-join-us-section></app-join-us-section>
</div>
