import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map, Observable} from 'rxjs';
import {ConstantsService} from './constants.service';
import {FaqCategory} from '../models/faq-model';
import {OgMetaModel} from '../models/og-meta-model';
import {GetRequestCachingHttpClient} from './request-caching/get-request-caching-http-client';
import {ReferencesHelperService} from './references-helper.service';
import {PartnerTypeModel} from '../models/partner-type.model';
import {ScheduleEntryModel} from '../models/schedule-entry.model';
import {MenuItemModel} from '../models/menu-item.model';
import {WorkshopModel} from '../models/workshops.model';
import {BasePageModel} from '../models/base-page.model';
import {PartnerModel} from '../models/partner-model';
import {EventModel} from '../models/event.model';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(private http: HttpClient,
                private constants: ConstantsService,
                private gRCHC: GetRequestCachingHttpClient,
                private refHelper: ReferencesHelperService) {
    }

    public addSubscriber(email: string): Observable<any> {
        const url = `${this.constants.api}/Subscriber/Add`;
        return this.http.post(url, {email: email});
    }

    public addTrack(code: string): Observable<any> {
        const url = `${this.constants.api}/ReferenceTrack/Add`;
        return this.http.post(url, {code: code});
    }

    public getSettings(): Observable<any> {
        const url = `${this.constants.api}/Setting/GetAll`;
        return this.gRCHC.get<{ data: any }>(url).pipe(map(r => r.data));
    }

    public getFaqs(): Observable<FaqCategory[]> {
        const url = `${this.constants.api}/Faq/GetAll`;
        return this.http.get<{ data: FaqCategory[] }>(url).pipe(map(r => r.data));
    }

    public getMetaData(id: string): Observable<OgMetaModel> {
        const url = `${this.constants.api}/OgMetadata/GetOne/${id}`;
        return this.http.get<{ data: OgMetaModel }>(url).pipe(map(r => r.data));
    }

    public async getPartners(): Promise<PartnerTypeModel[]> {
        const url = `${this.constants.api}/Partner/GetByTypes`;
        const partnerTypes = await firstValueFrom(this.http.get<{
            data: PartnerTypeModel[]
        }>(url).pipe(map(r => r.data)));
        const partnerTypes2 = JSON.parse(JSON.stringify(partnerTypes));
        this.refHelper.populateReferences(partnerTypes2);
        return partnerTypes2;
    }

    public getAllPartners(): Promise<PartnerModel[]> {
        const url = `${this.constants.api}/Partner/GetAll`;
        return firstValueFrom(this.http.get<{ data: PartnerModel[] }>(url).pipe(map(r => r.data)));
    }

    public async getSetting(settingSlug: string, categoryName?: string): Promise<any> {
        const settings = await firstValueFrom(this.getSettings());
        if (categoryName) {
            const category = settings[categoryName];
            return category && category[settingSlug] || undefined;
        }
        return settings[settingSlug];
    }

    public async getSettingOrThrow(settingSlug: string, categoryName?: string): Promise<any> {
        const setting = await this.getSetting(settingSlug, categoryName);
        if (typeof setting === 'undefined') {
            throw new Error('Required setting not found: ' + settingSlug);
        }
        return setting;
    }

    public getSchedule(): Observable<ScheduleEntryModel[]> {
        const url = `${this.constants.api}/ScheduleEntry/GetAll`;
        return this.http.get<{ data: ScheduleEntryModel[] }>(url).pipe(map(r => r.data));
    }

    public getMenuItems(): Observable<MenuItemModel[]> {
        const url = `${this.constants.api}/MenuItems/GetAll`;
        return this.http.get<{ data: MenuItemModel[] }>(url).pipe(map(r => r.data));
    }

    public async getWorkshops(): Promise<WorkshopModel[]> {
        const url = `${this.constants.api}/Workshops/GetAll`;
        return await firstValueFrom(this.http.get<{ data: WorkshopModel[] }>(url).pipe(map(r => r.data)));
    }

    public getPages(): Observable<BasePageModel[]> {
        const url = `${this.constants.api}/Pages/GetAll`;
        return this.http.get<{ data: BasePageModel[] }>(url).pipe(map(r => r.data));
    }

    public async getPage(slug: String): Promise<BasePageModel> {
        const url = `${this.constants.api}/Pages/GetBySlug/${slug}`;
        const response = await firstValueFrom(this.http.get<any>(url));
        return response.data;
    }

    public getArticles(): Observable<BasePageModel[]> {
        const url = `${this.constants.api}/Articles/GetAll`;
        return this.http.get<{ data: BasePageModel[] }>(url).pipe(map(r => r.data));
    }

    public getEvents(): Promise<EventModel[]> {
        const url = `${this.constants.api}/Events/GetAll`;
        return firstValueFrom(this.http.get<{ data: EventModel[] }>(url).pipe(map(r => r.data)));
    }

    public async getArticle(slug: String): Promise<BasePageModel> {
        const url = `${this.constants.api}/Articles/GetBySlug/${slug}`;
        const response = await firstValueFrom(this.http.get<any>(url));
        return response.data;
    }
}
