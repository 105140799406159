import { Injectable } from '@angular/core';
import { TestimonialModel } from '../models/testimonial-model';

@Injectable({
    providedIn: 'root',
})
export class TestimonialsSectionService {

    public testimonials: TestimonialModel[] = [
        {
            name: 'Pok Sosathya',
            country: 'Cambodia',
            message: 'It was an unforgettable memory.',
        },
        {
            name: 'Alisa',
            country: 'Romania',
            message: 'ISWinT is about making new friendships and also consolidate the existing ones!',
        },
        {
            name: 'Mariam',
            country: 'Armenia',
            message: 'It makes you feel different and unique, but at the same time so close and so connected to the world.',
        },
    ];

    public getTestimonials(): TestimonialModel[] {
        return this.testimonials;
    }
}
