import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { WorkshopModel } from '../../../../models/workshops.model';

@Component({
    selector: 'app-workshops-list-section',
    templateUrl: './workshops-list-section.component.html',
    styleUrls: ['./workshops-list-section.component.scss']
})
export class WorkshopsListSectionComponent implements OnInit {

    public workshops;
    public wsLength;

    constructor(private apiService: ApiService) {
    }

    async ngOnInit() {
        const workshops = await this.apiService
            .getWorkshops();
        this.workshops = workshops
            .map((workshop: WorkshopModel) =>
                '#' + workshop.name.trim().split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join('')
            );
        this.wsLength = this.workshops.length;
    }

}
