<div class="flex flex-row mt-8">
    <a class="me-4"
       href="portal?utm_source=frontpage&utm_medium=banner&utm_campaign=applications">
        <app-cta [label]="canEditVisa ? 'SEND US YOUR VISA!' : 'APPLY NOW!'"></app-cta>
    </a>
    <app-cta-arrow *ngIf="!canEditVisa" [target]="'_blank'" class="arrow-button"
                   goto="https://iswint.ro/content/upload/files/How-to-apply_b6e0ac07e8.pdf"
                   label="How to apply"></app-cta-arrow>

</div>
<small class="mt-4 notation">Applications open until <b>30.06.2023</b> at 23:59 UTC +2. <i>(if you
    <b>don't need visa</b>)</i></small><br>
