import {APP_ID, NgModule} from '@angular/core';
import {LazyLoadImageModule} from 'ng-lazyload-image'; // <-- import it
import {AppComponent} from './app.component';
import {FaqPageComponent} from './pages/faq-page/faq-page.component';
import {HomePageComponent} from './pages/home-page/home-page/home-page.component';
import {HeaderMenuComponent} from './components/header-menu/header-menu.component';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {FooterComponent} from './components/footer/footer.component';
import {PrivacyPolicyPageComponent} from './pages/privacy-policy-page/privacy-policy-page.component';
import {PageResolver} from './resolvers/page.resolver';
import {CookiesPopupComponent} from './components/cookies-popup/cookies-popup.component';
import {PartnersComponent} from './pages/home-page/components/partners/partners.component';
import {PathOnlyTransferHttpCacheInterceptor} from './services/request-caching/PathOnlyTransferHttpCacheInterceptor';
import {UrlParse} from './services/url-parse.service';
import {MapComponent} from './pages/home-page/components/map/map.component';
import {ScheduleComponent} from './pages/schedule/schedule.component';
import {ApplyNowSectionComponent} from './pages/home-page/components/apply-now-section/apply-now-section.component';
import {
    CheckWorkshopsSectionComponent
} from './pages/home-page/components/check-workshops-section/check-workshops-section.component';
import {
    CheckActivitiesSectionComponent
} from './pages/home-page/components/check-activities-section/check-activities-section.component';
import {ThemeSectionComponent} from './pages/home-page/components/theme-section/theme-section.component';
import {
    WorkshopsListSectionComponent
} from './pages/home-page/components/workshops-list-section/workshops-list-section.component';
import {
    TestimonialsSectionComponent
} from './pages/home-page/components/testimonials-section/testimonials-section.component';
import {JoinUsSectionComponent} from './pages/home-page/components/join-us-section/join-us-section.component';
import {CtaArrowComponent} from './components/common/cta-arrow/cta-arrow.component';
import {CtaComponent} from './components/common/cta/cta.component';
import {WorkshopPageComponent} from './pages/workshop-page/workshop-page.component';
import {
    ContentComponentComponent
} from './pages/workshop-page/components/content-component/content-component.component';
import {BlogsPageComponent} from './pages/blogs-page/blogs-page.component';
import {BlogComponent} from './pages/blogs-page/components/blog/blog.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {BasePageComponent} from './pages/base-page/base-page.component';
import {BrowserModule} from '@angular/platform-browser';
import {
    FundsYourInitiativeSectionComponent
} from './pages/home-page/components/funds-your-initiative-section/funds-your-initiative-section.component';
import {EventsPageComponent} from './pages/events-page/events-page.component';
import {EventCardComponent} from './pages/events-page/event-card/event-card.component';
import {EventDatePipe} from './pages/events-page/event-card/event-date.pipe';
import {EventsSectionComponent} from './pages/home-page/components/events-section/events-section.component';
import {WinnersSectionComponent} from './pages/home-page/components/winners-section/winners-section.component';
import {
    ParticipationFeeComponent
} from "./pages/home-page/components/apply-now-section/participation-fee/participation-fee.component";
import {
    ShortDescriptionComponent
} from "./pages/home-page/components/apply-now-section/short-description/short-description.component";
import {
    ApplyNowButtonsComponent
} from "./pages/home-page/components/apply-now-section/apply-now-buttons/apply-now-buttons.component";
import {
    AftermovieVideoComponent
} from "./pages/home-page/components/apply-now-section/aftermovie-video/aftermovie-video.component";
import {NgOptimizedImage} from "@angular/common";
import {RouterLink, RouterOutlet} from "@angular/router";


const pages = [
    FaqPageComponent,
    HomePageComponent,
    PrivacyPolicyPageComponent,
    BasePageComponent,
    EventsPageComponent,
];

const components = [
    HeaderMenuComponent,
    FooterComponent,
    PartnersComponent,
    MapComponent,
    CtaArrowComponent,
    CtaComponent,
];

@NgModule({
    declarations: [
        AppComponent,
        ...pages,
        ...components,
        CookiesPopupComponent,
        ScheduleComponent,
        ApplyNowSectionComponent,
        CheckWorkshopsSectionComponent,
        CheckActivitiesSectionComponent,
        ThemeSectionComponent,
        WorkshopsListSectionComponent,
        TestimonialsSectionComponent,
        JoinUsSectionComponent,
        BlogsPageComponent,
        BlogComponent,
        PageTitleComponent,
        WorkshopPageComponent,
        ContentComponentComponent,
        FundsYourInitiativeSectionComponent,
        EventCardComponent,
        EventDatePipe,
        EventsSectionComponent,
        WinnersSectionComponent,
        ParticipationFeeComponent,
        ShortDescriptionComponent,
        ApplyNowButtonsComponent,
        AftermovieVideoComponent
    ],
    imports: [
        BrowserModule,
        LazyLoadImageModule,
        FormsModule,
        HttpClientModule,
        NgxPageScrollModule,
        NgbCarouselModule,
        NgOptimizedImage,
        RouterOutlet,
        RouterLink,
        AppRoutingModule
    ],
    providers: [
        PageResolver,
        UrlParse,
        PathOnlyTransferHttpCacheInterceptor,
        {provide: APP_ID, useValue: 'iswint-website'},
        {provide: HTTP_INTERCEPTORS, useExisting: PathOnlyTransferHttpCacheInterceptor, multi: true},
    ],
    exports: [
        EventsSectionComponent,
    ],
})

export class AppModule {
    constructor() {
    }
}
